import React, { useState, useEffect } from 'react';
import './App.css';
import './Global/shared-css.css';
import { Container, Spinner } from 'react-bootstrap';
import Countdown from 'react-countdown';
import { initializeApp } from "firebase/app";
import { getFirestore, getDoc, doc, updateDoc, increment, arrayUnion } from "firebase/firestore";
import Err404 from './pages/404';
import { getAnalytics } from "firebase/analytics";
import { logEvent } from "firebase/analytics";

// Disable right-click
document.addEventListener('contextmenu', (e) => e.preventDefault());

function ctrlShiftKey(e, keyCode) {
  return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
}

document.onkeydown = (e) => {
  // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
  if (
    event.keyCode === 123 ||
    ctrlShiftKey(e, 'I') ||
    ctrlShiftKey(e, 'J') ||
    ctrlShiftKey(e, 'C') ||
    (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
  )
    return false;
};

const firebaseConfig = {
  apiKey: "AIzaSyCXxNVxVEYMUPP1oF-aatik7-yrKOZk8dY",
  authDomain: "gonugmm.firebaseapp.com",
  databaseURL: "https://gonugmm-default-rtdb.firebaseio.com",
  projectId: "gonugmm",
  storageBucket: "gonugmm.appspot.com",
  messagingSenderId: "1012307687730",
  appId: "1:1012307687730:web:6f5faa7be878fec826bf2b",
  measurementId: "G-Y4YTBW7HJZ"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);

var windowLocationPath = '';

let source = 'direct'; // Default source

if (window.location.search.includes('fbclid')) {
  source = 'facebook';
} else if (window.location.search.includes('s=q')) {
  source = 'qr';
} else if (window.location.search.includes('utm_source')) {
  let urlParams = new URLSearchParams(window.location.search);
  source = urlParams.get('utm_source');
} else if (window.location.search.includes('ref')) {
  source = 'other';
}

if (window.location.pathname.includes('?fbclid') || window.location.pathname.includes('?utm') || window.location.pathname.includes('?ref') || window.location.pathname.includes('/?s')) {
  windowLocationPath = window.location.pathname.split('?')[0];
} else {
  windowLocationPath = window.location.pathname;
}

console.log('windowLocationPath', windowLocationPath);

let country = '';
let countryCode = '';
let ip = '';
let isp = '';
let city = '';

async function fetchCountryData() {
  // Get user's IP address
  const response = await fetch('https://api.ipify.org?format=json');
  const data = await response.json();
  const ipAddress = data.ip;

  // Get user's country
  const geoResponse = await fetch(`https://ipapi.co/${ipAddress}/json/`);
  const geoData = await geoResponse.json();
  country = geoData.country_name;
  countryCode = geoData.country_code;
  ip = geoData.ip;
  isp = geoData.org;
  city = geoData.city;

  return country, countryCode, ip, isp, city;
}

// Usage
fetchCountryData().then(data => {
  console.log(data); // { country: '...', countryCode: '...', ip: '...', isp: '...' }
});

function getOperatingSystem() {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

  if (macosPlatforms.indexOf(platform) !== -1) {
    return 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    return 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    return 'Windows';
  } else if (/Android/.test(userAgent)) {
    return 'Android';
  } else if (!platform && /Linux/.test(userAgent)) {
    return 'Linux';
  }

  return 'Unknown';
}

let browserName = '';

function getBrowserName() {
  var userAgent = navigator.userAgent;

  // Detect browser
  if (userAgent.indexOf("Chrome") > -1) {
    browserName = "Google Chrome";
  } else if (userAgent.indexOf("Safari") > -1) {
    browserName = "Apple Safari";
  } else if (userAgent.indexOf("Firefox") > -1) {
    browserName = "Mozilla Firefox";
  } else if (userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident") > -1) {
    browserName = "Microsoft Internet Explorer";
  } else if (userAgent.indexOf("Edge") > -1) {
    browserName = "Microsoft Edge";
  } else if (userAgent.indexOf("Samsung") > -1) {
    browserName = "Samsung Internet";
  } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
    browserName = "Opera";
  } else if (userAgent.indexOf("UCBrowser") > -1) {
    browserName = "UC Browser";
  } else {
    browserName = "Unknown";
  }

  return browserName;
}

getBrowserName();

// Get current time
const time = new Date();

// Get user's device
const device = navigator.userAgent;

const CheckData = () => {
  var currentdisplayurl = windowLocationPath;
  var trimcdl = currentdisplayurl.substring(1);
  const [isActive, setIsActive] = useState('');
  const [fsRedirectUrl, setFsRedirectUrl] = useState('');
  const [isProtected, setIsProtected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [thingyan, setThingyan] = useState(false);

  let startThingyanDate = new Date('4/13/2024').toLocaleDateString();
  let endThingyanDate = new Date('4/17/2024').toLocaleDateString();

  useEffect(() => {
    let currentDate = new Date().toLocaleDateString();

    if (currentDate >= startThingyanDate && currentDate <= endThingyanDate) {
      setThingyan(true);
    } else {
      setThingyan(false);
    }

    let isMounted = true; // add a flag to track whether component is mounted

    const fetchData = async () => {
      try {
        setLoading(true);
        const documentRef = doc(db, 'goto', trimcdl);
        const documentSnapshot = await getDoc(documentRef);

        if (isMounted) { // only proceed if the component is still mounted
          if (documentSnapshot.exists()) {
            fetchCountryData();
            const data = documentSnapshot.data();
            const dataValue = data && data.url;
            setIsActive(data && data.active);
            if (isActive === false) {
              setFsRedirectUrl('null');
            } else {
              setFsRedirectUrl(dataValue);
            }
            setIsProtected(data && data.isprotected);

            // Log an event
            logEvent(analytics, 'visit_shortened_link', {
              name: trimcdl,
              country: country,
              time: time.toString(),
              device: device
            });

            // Increment counts field
            await updateDoc(documentRef, {
              clicks: increment(1)
            });

            // const currentTimeStamp = new Date().toString();
            let currentDate = new Date().toLocaleDateString('en-GB').replace(/\//g, '_');
            const browserInfo = navigator.userAgent;

            let activity = {
              "name": trimcdl,
              "country": country,
              "countryCode": countryCode,
              "city": city,
              "ip": ip,
              "isp": isp,
              "device": getOperatingSystem(),
              "timestamp": new Date(),
              "browser": browserName,
              "browserInfo": browserInfo,
              "source": source
            };

            try {
              await updateDoc(doc(db, "linkStatistics", trimcdl), {

                [currentDate]: arrayUnion(activity)

              });
            } catch (error) {
              return error;
              // console.error('Error fetching data: ', error);
            }

            console.log('gotodata', 'found with ' + trimcdl);
          } else {
            console.log('gotodata', 'not found with ' + trimcdl);
            setFsRedirectUrl('null');
            setIsActive(false); // Set isActive to false if document doesn't exist
          }
        }
      } catch (error) {
        if (isMounted) {
          setFsRedirectUrl('null');
        }
        console.error('Error fetching data: ', error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false; // clean up on unmount
    };
  }, [trimcdl]);

  return { fsRedirectUrl, isActive, isProtected, loading, thingyan };

};

function App() {
  const { fsRedirectUrl, isActive, isProtected, loading, thingyan } = CheckData();
  const currentYear = new Date().getFullYear();
  // const [is404, setIs404] = useState(false);

  // const domainname = 'http://localhost:3000';

  // const [loadloop, setLoadLoop] = loading;

  // const [redirectSeconds, setRedirectSeconds] = useState(6);
  const [isRedirecting, setIsRedirecting] = useState(false);

  // function getCurrentDomainName() {
  //   return window.location.hostname;
  // }

  // const [domainName, setDomainName] = useState(getCurrentDomainName());
  // const [isGoNUG, setIsGoNUG] = useState(false);
  // const [isEduNUG, setIsEduNUG] = useState(false);
  // const [isFederalEduNUG, setIsFederalEduNUG] = useState(false);

  // const [logoURL, setLogoURL] = useState('');

  // if (window.location.hostname === 'https://go.nugmyanmar.org') {
  //   setIsGoNUG(true);
  //   setLogoURL('https://cdn.egov.nugmyanmar.org/assets/state/nug_state_logo.svg');
  // } else if (window.location.hostname === 'https://edu.nugmyanmar.org') {
  //   setIsEduNUG(true);
  //   setLogoURL('https://cdn.egov.nugmyanmar.org/assets/state/nug_state_logo.svg');
  // } else if (window.location.hostname === 'https://for.moeedu.org') {
  //   setIsFederalEduNUG(true);
  //   setLogoURL('https://cdn.egov.nugmyanmar.org/assets/agencylogo/MOE.jpg');
  // } else {
  //   setIsGoNUG(false);
  //   setIsEduNUG(false);
  //   setIsFederalEduNUG(false);
  //   setLogoURL('https://cdn.egov.nugmyanmar.org/assets/agencylogo/MOE.jpg');
  // }

  var replacemm;

  const redirect = () => {
    let redirecturl = '';

    if (window.location.pathname === '/' || window.location.pathname === '') {
      redirecturl = 'https://info.go.nugmyanmar.org';
    } else if (isProtected === true) {
      redirecturl = `/p?cpass=${encodeURIComponent(windowLocationPath)}`;
    } else {
      redirecturl = fsRedirectUrl === 'null' ? '/404' : fsRedirectUrl;
    }

    console.log('Redirecting to:', redirecturl);

    fsRedirectUrl === 'null' ? <Err404 /> : window.location.replace(redirecturl);
  };

  const handleCountdownComplete = () => {
    setIsRedirecting(true);
    redirect();
  };

  const renderer = ({ seconds, completed }) => {

    if (window.location.pathname === '/' || window.location.pathname === '') {
      return redirect('https://info.go.nugmyanmar.org')
    }

    if (fsRedirectUrl === 'null' || isActive === false) {
      return <Err404 />
    }

    if (loading) {
      return (
        <>
          <div className="App">
            <Container fluid className="nug-top-banner">

              <Container className="nug-top-banner masthead-root">
                <img className="state-emblem" src="https://cdn.egov.nugmyanmar.org/assets/state/nug_state_logo.svg" alt="Myanmar State Emblem" width="30" height="25" />
                <a className='nugtoptxt' href="https://nugmyanmar.org" target="_blank" rel="noopener noreferrer">An Official Website of the National Unity Government
                </a>
              </Container>

            </Container>
            <Container fluid className='uppercontainer'>
              <h2>Check your address bar</h2>
              <p className='beware'>Beware of phishing! Make sure your link starts with <b>go.nugmyanmar.org</b></p>
              <Spinner className='spinnermargin' animation="grow" size="sm" />
              {/* <img src='https://cdn.egov.nugmyanmar.org/assets/state/go.nugmyanmar.org-searchbar-icon.png' className='searchbarimg' /> */}
              <img src={thingyan ? 'https://cdn.egov.nugmyanmar.org/assets/state/thingyan.go.nug.png' : 'https://cdn.egov.nugmyanmar.org/assets/state/go.nugmyanmar.org-searchbar-icon.png'} alt='Go.NUG AddressBar' className='searchbarimg' />
            </Container>
            <Container fluid className='lowercontainer'>
              <h5 className='text-secondary'>သင်၏ Address Bar အားစစ်ဆေးပါ</h5>
              <p className='bewaremm text-secondary lh-lg'>ဖြားယောင်းခြင်းကို သတိပြုပါ။ သင့်လင့်ခ်သည် <b>go.nugmyanmar.org</b> ဖြင့် စတင်ရမည်ဖြစ်သည်။</p>
              <Spinner className='spinnermargin' animation="grow" variant="dark" size="sm" />
              <p className='redirectmm text-secondary'>© {currentYear} NUG Myanmar</p>
            </Container>
            {/* ... (remaining code remains unchanged) */}
          </div>
        </>
      );
    }

    if (completed && !isRedirecting) {
      // setLoading(false);
      // setRedirectSeconds(0);
    } else {
      replacemm = seconds.toString().replace(/[654321]/g, (match) => {
        if (match === '6') {
          return '၆';
        } else if (match === '5') {
          return '၅';
        } else if (match === '4') {
          return '၄';
        } else if (match === '3') {
          return '၃';
        } else if (match === '2') {
          return '၂';
        } else if (match === '1') {
          return '၁';
        }
        return match;
      });
    }

    if (isRedirecting) {
      return null;
    }

    return (
      <div className="App">
        <Container fluid className="nug-top-banner">

          <Container className="nug-top-banner masthead-root">
            <img className="state-emblem" src="https://cdn.egov.nugmyanmar.org/assets/state/nug_state_logo.svg" alt="Myanmar State Emblem" width="30" height="25" />
            <a className='nugtoptxt' href="https://nugmyanmar.org" target="_blank" rel="noopener noreferrer">An Official Website of the National Unity Government
            </a>
          </Container>

        </Container>
        <Container fluid className='uppercontainer'>
          <h2>Check your address bar</h2>
          <p className='beware'>Beware of phishing! Make sure your link starts with <b>go.nugmyanmar.org</b></p>
          <p className='redirect'>
            You will be redirected in {seconds} {seconds <= 1 ? 'second' : 'seconds'}
            <Spinner animation="border" size="sm" className='spinnerload' />
          </p>
          <img src={thingyan ? 'https://cdn.egov.nugmyanmar.org/assets/state/thingyan.go.nug.png' : 'https://cdn.egov.nugmyanmar.org/assets/state/go.nugmyanmar.org-searchbar-icon.png'} alt='Go.NUG AddressBar' className='searchbarimg' />
        </Container>
        <Container fluid className='lowercontainer'>
          <h5 className='text-secondary'>သင်၏ Address Bar အားစစ်ဆေးပါ</h5>
          <p className='bewaremm text-secondary lh-lg'>ဖြားယောင်းခြင်းကို သတိပြုပါ။ သင့်လင့်ခ်သည် <b>go.nugmyanmar.org</b> ဖြင့် စတင်ရမည်ဖြစ်သည်။</p>
          <p className='redirectmm text-secondary'>သင့်အား {replacemm} စက္ကန့်အတွင်း ပို့ဆောင်ပေးပါမည် <Spinner animation="border" size="sm" className='spinnerload' /></p>
          <p className='redirectmm text-secondary'>© {currentYear} NUG Myanmar</p>
        </Container>
        {/* ... (remaining code remains unchanged) */}
      </div>
    );
  };

  return (
    <div className="App">
      <header className="App-header">
        <Countdown
          date={isProtected === true ? Date.now() + 0 : Date.now() + 6000}
          renderer={renderer}
          onComplete={handleCountdownComplete}
        />
      </header>
    </div>
  );

}

export default App;