import React, { Component } from 'react';
import '../App.css';
import '../Global/shared-css.css';
import { Container } from 'react-bootstrap';

export default class Err404 extends Component {
    render() {
        return (

            <div className="App404" >
                <Container fluid className="nug-top-banner">

                    <Container className="nug-top-banner masthead-root">
                        <img className="state-emblem" src="https://cdn.egov.nugmyanmar.org/assets/state/nug_state_logo.svg" alt="Myanmar State Emblem" width="30" height="25" />
                        <a className='nugtoptxt' href="https://nugmyanmar.org" target="_blank" rel="noopener noreferrer">An Official Website of the National Unity Government
                        </a>
                    </Container>

                </Container>
                <header className="header404 bg">
                    <img src='https://cdn.egov.nugmyanmar.org/assets/state/linkunknown-go.nug.svg' alt='Link Broke IMG' className='notfounded' />
                    {/* <img src={concernloading} className="App-logo" alt="Loading" /> */}
                    <h4 className='text-dark'>
                        Unfortunately, the requested short link is unavailable at the moment.
                    </h4>
                    <p className='text-dark smalltext'>
                        For any additional support or inquiries, kindly reach out to the respective agency responsible for providing you with the short link. They will be more than happy to assist you further.
                    </p>
                </header>
            </div>
        );
    }
}